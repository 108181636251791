var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2"},[_c('div',{},[_c('vxe-button',{staticClass:"mb-2",attrs:{"size":"mini","status":"my-primary"},on:{"click":_vm.addSource}},[_vm._v(" 新增信息源 ")]),_c('div',[_c('vxe-table',{ref:"sourceList",staticClass:"my-scrollbar",staticStyle:{"width":"100%"},attrs:{"id":"sourceList","data":_vm.appStore.tagDetail.source,"show-overflow":"","show-header-overflow":"","highlight-hover-row":"","size":"small","sort-config":{
          trigger: 'cell',
          defaultSort: { field: 'pinyin', order: 'asc' },
          orders: ['desc', 'asc', null],
          multiple: true,
        },"scroll-x":{ gt: 30 },"scroll-y":{ mode: 'wheel', gt: 100 },"tooltip-config":{
          showAll: false,
          enterable: true,
          theme: 'dark',
        },"custom-config":{ storage: true },"filter-config":{ showIcon: false },"expand-config":{ trigger: 'row' },"border":"","row-config":{ isHover: true },"edit-config":{ trigger: 'click', mode: 'row' }}},[_c('vxe-column',{attrs:{"title":"类型","width":"80","field":"type","edit-render":{}},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
        var row = ref.row;
return [_c('vxe-select',{attrs:{"transfer":""},model:{value:(row.type),callback:function ($$v) {_vm.$set(row, "type", $$v)},expression:"row.type"}},_vm._l((_vm.typeList),function(item){return _c('vxe-option',{key:item.value,attrs:{"value":item.value,"label":item.label}})}),1)]}},{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.type ? row.type : "")+" ")])]}}])}),_c('vxe-column',{attrs:{"width":"200","field":"name","title":"来源名称","edit-render":{}},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
        var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}},{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.name ? row.name : "无数据--点击编辑")+" ")])]}}])}),_c('vxe-column',{attrs:{"min-width":"35","field":"url","title":"来源链接","edit-render":{}},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
        var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.url),callback:function ($$v) {_vm.$set(row, "url", $$v)},expression:"row.url"}})]}},{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(row.url ? row.url : "无数据--点击编辑")+" ")])]}}])}),(_vm.$commonHelpers.getPermissions('tagStore.editTag'))?_c('vxe-column',{attrs:{"fixed":"right","title":"操作","type":"seq","width":"100","resizable":false,"show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('div',[_c('a',{staticClass:"text-xs font-weight-normal mr-1 px-2",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.upRow(row)}}},[_vm._v(" ↑ ")]),_c('a',{staticClass:"text-xs font-weight-normal mr-1 px-2",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.downRow(row)}}},[_vm._v(" ↓ ")]),_c('a',{staticClass:"text-xs font-weight-normal text-red mr-1",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.deleteSource(row)}}},[_vm._v("删除")])])]}}],null,false,565425164)}):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }