<template>
  <div class="d-flex">
    <div style="width: 100%">
      <vxe-toolbar class="" custom v-if="!onlyShow">
        <template v-slot:buttons>
          <div>导入结果预览</div>
          <vxe-button
            class="px-1 ml-2"
            status="my-primary"
            @click="stockAdd()"
            size="medium"
            content="绑定新股票"
            v-if="$commonHelpers.getPermissions('tagStore.editTag')"
          ></vxe-button>

          <!-- <vxe-button
            class="px-1"
            status="my-primary"
            @click="multiDisplay = !multiDisplay"
            size="medium"
            :content="multiDisplay ? '关闭批量' : '批量操作'"
            v-if="$commonHelpers.getPermissions('tagStore.editTag')"
          >
          </vxe-button> -->
          <vxe-button
            class="px-1"
            status="my-primary"
            @click="groupEdit()"
            size="medium"
            content="批量分组"
            v-if="
              multiDisplay && $commonHelpers.getPermissions('tagStore.editTag')
            "
          ></vxe-button>
          <vxe-button
            class="px-1"
            status="my-danger"
            @click="multiDelete()"
            size="medium"
            content="批量删除"
            v-if="
              multiDisplay && $commonHelpers.getPermissions('tagStore.editTag')
            "
          ></vxe-button>

          <!-- <ColEdit
            v-if="$commonHelpers.getPermissions('tagStore.editTag')"
          ></ColEdit> -->

          <!-- <vxe-button
            transfer
            size="medium"
            status="my-primary"
            class="px-0 mx-1"
          >
            <template #default class="abc">Excel导入</template>
            <template #dropdowns>
              <vxe-button
                type="text"
                @click="downloadMuban()"
                v-if="$commonHelpers.getPermissions('tagStore.editTag')"
                >下载模板</vxe-button
              >
              <vxe-button
                type="text"
                @click="clickUpload()"
                v-if="$commonHelpers.getPermissions('tagStore.editTag')"
                >上传导入</vxe-button
              >
            </template>
          </vxe-button> -->

          <!-- <GroupOrder
            v-if="$commonHelpers.getPermissions('tagStore.editTag')"
          ></GroupOrder> -->
        </template>
      </vxe-toolbar>
      <vxe-table
        :data="appStore.tagDetail.stock"
        style="width: 100%"
        class="mytable-scrollbar"
        :border="false"
        resizable
        show-overflow
        show-header-overflow
        highlight-hover-row
        ref="bindStockTable"
        :loading="bindStockTableLoading"
        size="small"
        :sort-config="{ trigger: 'cell', multiple: true }"
        :empty-render="{ name: 'NotData' }"
        align="center"
        :tree-config="{ expandAll: true, reserve: false }"
        :checkbox-config="{ checkMethod: allowCheck, trigger: 'cell' }"
        :height="pageHeight - 240 + 'px'"
        :edit-config="{
          trigger: 'click',
          mode: 'cell',
          icon: ' ',
          activeMethod: activeCellMethod,
        }"
      >
        <vxe-column
          fixed="left"
          field="multi"
          type="checkbox"
          title="批量"
          width="80"
          v-if="multiDisplay"
        >
        </vxe-column>

        <vxe-column
          fixed="left"
          width="100"
          field="groupName"
          title="分组"
          tree-node
        >
          <template #default="{ row }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ row.groupName }}
            </div>
          </template>
        </vxe-column>

        <vxe-column fixed="left" width="70" field="code" title="股票代码">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>

          <template #default="{ row }">
            <div class="d-flex justify-start">
              <span v-if="row.pool">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.pool.id)"
                  class="text-xs"
                  style="font-weight: bolder"
                  :style="{ color: getStockColor(row) }"
                  >{{ row.code ? row.code : "-" }}</a
                >
              </span>
              <span v-else>
                <span :style="{ color: getStockColor(row) }">
                  {{ row.code ? row.code : "" }}
                </span>
              </span>
            </div>
          </template>
        </vxe-column>

        <vxe-column fixed="left" width="60" field="name" title="股票名称">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div class="d-flex justify-start">
              <span v-if="row.pool">
                <a
                  href="javascript:;"
                  @click="$stockHelpers.openStockDetail(row.pool.id)"
                  class="text-xs"
                  style="font-weight: bolder"
                  :style="{ color: getStockColor(row) }"
                  >{{ row.name ? row.name : "-" }}</a
                >
              </span>
              <span v-else>
                <span :style="{ color: getStockColor(row) }">
                  {{ row.name ? row.name : "" }}
                </span>
              </span>
            </div>
          </template>
        </vxe-column>

        <vxe-column min-width="200" field="reason" title="描述">
          <template #header="{ column }">
            <div style="word-wrap: break-word; white-space: pre-wrap">
              {{ column.title }}
            </div>
          </template>
          <template #default="{ row }">
            <div
              class="d-flex justify-start"
              :style="{
                color: row.reason
                  ? '#000000'
                  : row.groupName
                  ? '#000000'
                  : '#ff0000',
              }"
            >
              {{ row.reason ? row.reason : row.groupName ? "" : "未填写描述" }}
            </div>
          </template>
        </vxe-column>

        <vxe-column
          :edit-render="{ name: 'input' }"
          v-for="(label, index) in appStore.tagDetail.labels"
          :field="`label_data.${label.key}`"
          :title="label.name"
          :width="label.width"
          v-if="label.show == 1 && label.type == 3"
          :key="label.key"
        >
          <template #default="{ row }">
            <div class="d-flex justify-end" v-if="row.label_data">
              {{
                row.label_data[label.key]
                  ? row.label_data[label.key]
                  : "点击编辑"
              }}
            </div>
          </template>
          <template #edit="{ row }">
            <vxe-input
              :max="100"
              :min="0"
              v-model="row.label_data[label.key]"
              placeholder="请输入值"
              @change="rowChange = true"
              @blur="editRow(row)"
            ></vxe-input>
          </template>
        </vxe-column>

        <vxe-column
          fixed="right"
          title="操作"
          type="seq"
          width="200"
          :resizable="false"
          show-overflow
          v-if="$commonHelpers.getPermissions('tagStore.editTag') && !onlyShow"
        >
          <template #default="{ row }">
            <div v-if="row.code">
              <a
                href="javascript:;"
                @click="setImportant(row)"
                class="text-xs font-weight-normal text-typo mr-1"
                >{{ row.is_important === 0 ? "设为重点" : "取消重点" }}</a
              >
              <a
                href="javascript:;"
                @click="groupEdit(row)"
                class="text-xs font-weight-normal text-typo mr-1"
                >分组</a
              >
              <a
                href="javascript:;"
                @click="upRow(row, row.order != 0)"
                class="text-xs font-weight-normal mr-1 px-2"
                :class="{
                  'text-typo': row.order != 0,
                  'grey--text': row.order == 0,
                }"
              >
                ↑
              </a>
              <a
                href="javascript:;"
                @click="downRow(row, !row.last)"
                class="text-xs font-weight-normal mr-1 px-2"
                :class="{
                  'text-typo': !row.last,
                  'grey--text': row.last,
                }"
              >
                ↓
              </a>
              <a
                href="javascript:;"
                @click="descEdit(row)"
                class="text-xs font-weight-normal text-typo mr-1"
                >描述</a
              >
              <a
                href="javascript:;"
                @click="deleteStock(row)"
                class="text-xs font-weight-normal text-red mr-1"
                >删除</a
              >
            </div>
          </template>
        </vxe-column>
      </vxe-table>
    </div>

    <a ref="muban" style="display: none" href="/标签模板.xlsx">下载模板</a>
    <input
      style="display: none"
      ref="uploadInput"
      type="file"
      @change="startUpload"
    />
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import rate from "@/components/Rate/Rate.vue";
import BindStockAdd from "@/components/TagStore/BindStockAdd.vue";
import StockGroupEdit from "@/components/TagStore/StockGroupEdit.vue";
import StockDescEdit from "@/components/TagStore/StockDescEdit.vue";
import XEUtils from "xe-utils";
import Tag from "@/api/tag.js";
import StockPoolAdd from "@/components/StockPool/StockAdd.vue";
import GroupOrder from "@/components/TagStore/GroupOrder.vue";
import ColEdit from "@/components/TagStore/ColEdit.vue";

export default {
  name: "Dashboard",
  components: { GroupOrder, ColEdit },
  props: {
    currTag: {},
    onlyShow: false,
  },
  data() {
    return {
      stockList: [],
      labels: [],
      bindStockTableLoading: false,
      pageHeight: 0,

      multiDisplay: false,
      date: new Date(),
      rowChange: false,
      columns: [],
    };
  },
  mounted() {
    this.pageHeight = document.body.offsetHeight;
  },
  methods: {
    getStockColor(row) {
      if (row.is_important) {
        return "#ff0000";
      } else if (row.pool) {
        return "#0000ee";
      } else {
        return "#000000";
      }
    },
    //调入股票池
    addToPool(row) {
      //console.log(row);
      this.$layer.iframe({
        content: {
          content: StockPoolAdd,
          parent: this,
          data: {
            iframeData: { isOtc: false, stockId: row.id, stockName: row.name },
          },
        },
        area: ["600px", "650px"],
        title: "调入股票池(带*为必填)",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },

    stockAdd() {
      //console.log(this.currTag);
      this.$layer.iframe({
        content: {
          content: BindStockAdd,
          parent: this,
          data: { iframeData: { data: this.currTag } },
        },
        area: ["600px", "650px"],
        title: "新增绑定股票(带*为必填)",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },

    openRate(row) {
      this.$stockHelpers.openRate(row.id, row.name, this);
    },

    deleteStock(row) {
      let arr = [];
      this.currTag.stock.forEach((value, index) => {
        value.children.forEach((item) => {
          if (item.id != row.id) {
            arr.push(item.id);
          }
        });
      });
      //console.log(arr); return;
      this.$layer.confirm("确定要删除这个绑定的股票吗？", (layerid) => {
        this.showLoading("删除中，请稍候");
        Tag.bindStock(this.currTag.id, arr)
          .then((res) => {
            this.showToast("删除成功", "success", 5000);
            Bus.$emit("refreshTagDetail", true);

            this.$layer.close(this.layerid);
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },
    groupEdit(stock = "") {
      //console.log(stock);
      let oldGroupName = "";
      if (!stock) {
        let selectRecords = this.$refs.bindStockTable.getCheckboxRecords();
        if (selectRecords.length < 1) {
          this.showToast("请先选择项目", "warning", 5000);
          return;
        }
        let arr = [];
        selectRecords.forEach((i) => {
          arr.push(i.id);
          oldGroupName = i.race_tags;
        });
        stock = arr;
        console.log(stock);
      } else {
        oldGroupName = stock.race_tags;
        stock = [stock.id];
      }

      this.$layer.iframe({
        content: {
          content: StockGroupEdit,
          parent: this,
          data: {
            iframeData: {
              stockIds: stock,
              tagId: this.currTag.id,
              oldGroupName: oldGroupName,
            },
          },
        },
        area: ["600px", "650px"],
        title: "修改分组名(留空为取消分组)",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    multiDelete() {
      this.$layer.confirm("确定要删除这些绑定的股票吗？", (layerid) => {
        let selectRecords = this.$refs.bindStockTable.getCheckboxRecords();
        let selectArr = [];
        if (selectRecords.length < 1) {
          this.showToast("请先选择项目", "warning", 5000);
          return;
        }
        selectRecords.forEach((item, index) => {
          selectArr.push(item.id);
        });

        //过滤删除后的数组
        let currStockList = [];
        let tempStockList = JSON.parse(
          JSON.stringify(this.appStore.tagDetail.stock)
        );
        tempStockList.forEach((value, index) => {
          if (value.children) {
            value.children.forEach((item, i) => {
              if (selectArr.indexOf(item.id) == -1) {
                currStockList.push(item.id);
              }
            });
          }
        });
        this.showLoading("删除中，请稍候");
        Tag.bindStock(this.currTag.id, currStockList)
          .then((res) => {
            this.showToast("删除成功", "success", 5000);
            Bus.$emit("refreshTagDetail", true);

            this.$layer.close(this.layerid);
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },
    descEdit(stock = "") {
      let oldDesc = "";
      if (!stock) {
        let selectRecords = this.$refs.bindStockTable.getCheckboxRecords();
        if (selectRecords.length < 1) {
          this.showToast("请先选择项目", "warning", 5000);
          return;
        }
        let arr = [];
        selectRecords.forEach((i) => {
          arr.push(i.id);
          oldDesc = i.reason;
        });
        stock = arr;
      } else {
        oldDesc = stock.reason;
        stock = [stock.id];
      }

      this.$layer.iframe({
        content: {
          content: StockDescEdit,
          parent: this,
          data: {
            iframeData: {
              stockIds: stock,
              tagId: this.currTag.id,
              oldDesc: oldDesc,
            },
          },
        },
        area: ["600px", "650px"],
        title: "修改企业描述",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    allowCheck({ row }) {
      if (row.tag) {
        return row.tag.indexOf(",") == -1 ? true : false;
      } else if (!row.name) {
        return false;
      } else {
        return true;
      }
    },

    //编辑自定义字段
    editRow(row) {
      if (this.rowChange) {
        this.rowChange = false;

        //处理数据
        let data = {
          race_id: this.appStore.tagDetail.id,
          stock_id: row.id,
        };

        data.label_data = row.label_data;

        this.showLoading("保存中，请稍候");
        Tag.editTagStockRow(data)
          .then((res) => {
            this.showToast("保存成功", "success", 5000);
            //Bus.$emit("refreshTagDetail", true);
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 10000);
          })
          .finally(() => {
            this.hideLoading();
          });
      }
    },
    activeCellMethod({ row, column }) {
      if (!row.groupName) {
        // if (column.field == "rate_date") {
        // 	this.dateDisabled = row.parent_category_name == '量价' ? false : true;
        // }
        return true;
      } else {
        return false;
      }
    },
    downloadMuban() {
      this.$refs.muban.click();
    },
    clickUpload() {
      this.$refs.uploadInput.click();
    },
    startUpload() {
      this.showLoading("导入中，请稍候！");
      let file = this.$refs.uploadInput.files[0];
      Tag.uploadExcel(this.currTag.id, file)
        .then((res) => {
          console.log(res.data);
          this.showToast("导入成功，请等待数据刷新！", "success", 10000);
          Bus.$emit("refreshTagDetail", true);
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
          this.$refs.uploadInput.value = "";
        });
    },
    setImportant(row) {
      console.log(this.currTag.level);
      let is_important = 0;
      if (row.is_important === 0) {
        is_important = 1;
      }
      this.showLoading("保存中，请稍候！");
      Tag.setImportant(this.currTag.id, [row.id], is_important)
        .then((res) => {
          console.log(res.data);
          this.showToast("设置成功！", "success", 10000);
          //vuex刷新列表
          this.appStore.tagDetail.stock.forEach((value, index) => {
            if (value.children) {
              value.children.forEach((item, i) => {
                if (item.id == row.id) {
                  item.is_important = is_important;
                }
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    reasonBlur(value, row) {
      console.log(value, row);

      //this.showLoading("保存描述中，请稍候");
      Tag.editStockDesc(this.currTag.id, [row.id], value.value)
        .then((res) => {
          this.showToast("保存描述成功", "success", 5000);
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          //this.hideLoading();
        });
    },
    upRow(row, success) {
      if (!success) {
        this.showToast("已经是第一条了", "warning", 1000);
        return;
      }
      let that = this;
      let tempTag = [];

      // this.appStore.tagDetail.stock.forEach((value, index) => {
      this.currTag.stock.forEach((value, index) => {
        if (value.children) {
          let tempArr = [];
          value.children.forEach((item, i) => {
            if (item.id == row.id && value.groupName == row.race_tag) {
              if (value.children[i - 1]) {
                item.order--;
                value.children[i - 1].order++;
                if (tempArr[i - 1]) {
                  tempArr[i - 1].order++;
                }
              }
            }
            tempArr.push({ id: item.id, order: item.order });
          });
          tempTag.push({ tag: value.groupName, stocks: tempArr });
        }
      });
      this.currTag.stock.forEach((value, index) => {
        if (value.children) {
          let tempArr = [];
          value.children.forEach((item, i) => {
            if (item.id == row.id && value.groupName == row.race_tag) {
              let oldList = [...value.children];
              let newList = [...value.children];
              // 将当前数组下标的为1的item删除
              newList.splice(i, 1); // 当前返回 ['内容1','内容3','内容4','内容5']
              // 在当前数组下标的前一个数组下标添加 当前数组下标的item
              newList.splice(i - 1, 0, oldList[i]); // 当前返回 ['内容2','内容1','内容3','内容4','内容5']
              value.children = [...newList];
            }
            if (item.order == value.children.length - 1) {
              item.last = true;
            } else {
              item.last = false;
            }
          });
        }
      });
      this.showLoading("保存中，请稍候！");
      Tag.setOrder(this.currTag.id, tempTag)
        .then((res) => {
          console.log(res.data);

          this.showToast("设置成功！", "success", 10000);
          //vuex刷新列表
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    downRow(row, success) {
      if (!success) {
        this.showToast("已经是最后一条了", "warning", 1000);
        return;
      }
      // console.log(this.currTag);
      console.log(row);
      let that = this;
      let tempTag = [];

      // this.appStore.tagDetail.stock.forEach((value, index) => {
      this.currTag.stock.forEach((value, index) => {
        if (value.children) {
          let tempArr = [];
          value.children.forEach((item, i) => {
            if (item.id == row.id && value.groupName == row.race_tag) {
              if (value.children[i + 1]) {
                item.order++;
                value.children[i + 1].order--;
                if (tempArr[i + 1]) {
                  tempArr[i + 1].order--;
                }
              }
            }

            tempArr.push({ id: item.id, order: item.order });
          });
          tempTag.push({ tag: value.groupName, stocks: tempArr });
        }
      });
      this.currTag.stock.forEach((value, index) => {
        if (value.children) {
          let tempArr = [];
          value.children.forEach((item, i) => {
            if (item.id == row.id && value.groupName == row.race_tag) {
              let oldList = [...value.children];
              let newList = [...value.children];
              // 将当前数组下标的为1的item删除
              newList.splice(i, 1); // 当前返回 ['内容1','内容3','内容4','内容5']
              // 在当前数组下标的前一个数组下标添加 当前数组下标的item
              newList.splice(i + 1, 0, oldList[i]); // 当前返回 ['内容2','内容1','内容3','内容4','内容5']
              value.children = [...newList];
            }
            if (item.order == value.children.length - 1) {
              item.last = true;
            } else {
              item.last = false;
            }
          });
        }
      });
      this.showLoading("保存中，请稍候！");
      Tag.setOrder(this.currTag.id, tempTag)
        .then((res) => {
          console.log(res.data);

          this.showToast("设置成功！", "success", 10000);
          //vuex刷新列表
        })
        .catch((err) => {
          console.log(err);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
  watch: {
    currTag(val) {
      this.labels = val.labels;
      setTimeout(() => {
        this.$refs.bindStockTable.setAllTreeExpand(true);

        this.columns = this.$refs.bindStockTable.getColumns();
        // //控制列的显隐
        // this.labels.forEach((i) => {
        //   if (i.show == 1) {
        //     this.$refs.bindStockTable.showColumn(
        //       this.$refs.bindStockTable.getColumnByField(i.key)
        //     );
        //     if (this.$refs.bindStockTable.getColumnByField(i.key)) {
        //       if (this.$refs.bindStockTable.getColumnByField(i.key).children) {
        //         this.$refs.bindStockTable
        //           .getColumnByField(i.key)
        //           .children.forEach((j) => {
        //             this.$refs.bindStockTable.showColumn(
        //               this.$refs.bindStockTable.getColumnByField(j.field)
        //             );
        //           });
        //       }
        //     }
        //   } else {
        //     this.$refs.bindStockTable.hideColumn(
        //       this.$refs.bindStockTable.getColumnByField(i.key)
        //     );
        //     if (this.$refs.bindStockTable.getColumnByField(i.key).children) {
        //       this.$refs.bindStockTable
        //         .getColumnByField(i.key)
        //         .children.forEach((j) => {
        //           this.$refs.bindStockTable.hideColumn(
        //             this.$refs.bindStockTable.getColumnByField(j.field)
        //           );
        //         });
        //     }
        //   }
        // });
      }, 100);
    },
  },
};
</script>

<style scoped>
.roles-dropdown {
  width: 200px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}
</style>
