<template>
  <div>
    <div
      id="mindMapContainer"
      :style="{ height: viewHeight - 210 + 'px' }"
    ></div>

    <div
      id="stockReason"
      v-show="stockReasonDisplay"
      v-html="stockReason"
    ></div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import MindMap from "simple-mind-map";
export default {
  name: "KnowledgeGraph",
  components: {},
  props: {
    tagDetail: {},
    viewHeight: 0,
  },

  data() {
    return {
      stockReasonDisplay: false,
      stockReason: "",
    };
  },
  computed: {},
  mounted() {
    console.log(this.tagDetail);
    this.draw();
  },
  methods: {
    draw() {
      //将stock转换为思维导图的结构
      let nodeRoot = {
        data: {
          text: this.tagDetail.name,
        },
        children: [],
      };
      this.tagDetail.stock.forEach((item) => {
        let node = {
          data: {
            text: item.groupName,
          },
          children: [],
        };
        //如果有info字段，就新增一层增加分组描述
        if (item.info) {
          node.data.generalization = {
            text: `<span style="font-size:12px">${item.info}</span>`,
            richText: true,
            borderWidth: 0,
          };
        }
        let stocks = [];
        //添加分组的股票
        if (item.children) {
          item.children.forEach((child) => {
            let stockText = "";
            if (child.pool) {
              stockText = `<a target="_blank" style="text-decoration:none;color:#bd0000"
                  href="${location.origin + "/StockDetail/" + child.id}"
                  >${child.name}(${child.code})
                </a>`;
            } else {
              stockText = `<a target="_blank" style="text-decoration:none;color:#000"
                  href="${location.origin + "/StockDetail/" + child.id}"
                  >${child.name}(${child.code})
                </a>`;
            }
            let stockData = {
              data: {
                text: stockText,
                richText: true,
                //note: `<span style="font-size:12px">${child.reason}</span`,
              },
              children: [],
            };
            if (child.reason) {
              stockData.children.push({
                data: {
                  text: `<span style="font-size:12px">${child.reason}</span>`,
                  richText: true,
                },
                children: [],
              });
            }
            stocks.push(stockData);
          });
          node.children = stocks;
        }

        nodeRoot.children.push(node);
      });
      //console.log(nodeRoot);
      const mindMap = new MindMap({
        el: document.getElementById("mindMapContainer"),
        layout: "mindMap",
        theme: "blueSky",
        data: nodeRoot,
        mouseScaleCenterUseMousePosition: false,
        readonly: true,
        customNoteContentShow: {
          show: (content, left, top) => {
            this.stockReasonDisplay = true;
            this.stockReason = content;
            document.getElementById("stockReason").style.left = left + "px";
            document.getElementById("stockReason").style.top = top + "px";
          },
          hide: () => {
            this.stockReasonDisplay = false;
          },
        },
      });
    },
  },
  watch: {},
};
</script>
<style scoped="scoped">
#mindMapContainer * {
  margin: 0;
  padding: 0;
}
#stockReason {
  position: fixed;
  z-index: 999;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 3px rgba(0, 0, 0, 0.1); /*阴影半径为5px*/
}
</style>
