<template>
  <div class="mt-2">
    <div>
      <vxe-button
        status="my-primary"
        @click="isEditting = !isEditting"
        size="medium"
        :content="isEditting ? '取消编辑' : '编辑内容'"
        v-if="$commonHelpers.getPermissions('tagStore.editTag')"
      >
      </vxe-button>
      <vxe-button
        status="my-danger"
        @click="save()"
        size="medium"
        content="保存"
        v-if="isEditting"
      >
      </vxe-button>
      <span style="font-size: 13px; color: #ccc; margin-left: 10px"
        >更新时间：{{ tagDetail.snap_updated_at }}</span
      >
    </div>

    <Editor
      style="min-height: 50px"
      editorId="tagDataTableContent"
      :defaultConfig="editorConfig"
      @onCreated="onCreated"
    />
  </div>
</template>
<script>
import "@wangeditor/editor/dist/css/style.css";
import { Editor } from "@wangeditor/editor-for-vue";

import Tag from "@/api/tag.js";
import Bus from "@/components/Bus/bus.js";

import File from "@/api/file.js";
const OSS = require("ali-oss");
export default {
  name: "TagDataTable",
  props: {
    tagDetail: "",
  },
  components: { Editor },
  data() {
    return {
      editor: {},
      editorConfig: {
        placeholder: "暂无数据",
        MENU_CONF: {
          uploadImage: {
            customUpload: (file, insertFn) => {
              this.customUpload(file, insertFn);
            },
          },
        },
      },
      isEditting: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.editor.enable();
      this.editor.clear();

      if (this.tagDetail.snap) {
        this.editor.dangerouslyInsertHtml(this.tagDetail.snap);
      }

      this.editor.move(999999);
      this.editor.blur();
      this.editor.disable();

      let arr = this.editor.getElemsByType("image");
      arr.forEach((item) => {
        let el = document.getElementById(item.id);
        el.onclick = () => {
          Bus.$emit("previewFile", {
            url: item.src,
            name: item.alt + ".jpg",
          });
        };
      });
    }, 1000);
  },
  methods: {
    //编辑器初始化
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      this.editor.disable();
    },
    save() {
      let submitInfo = {};
      submitInfo.snap = this.editor.getHtml().replace("<p><br></p>", "");
      this.showLoading("保存中，请稍候");
      Tag.editTag(this.tagDetail.id, submitInfo)
        .then((res) => {
          this.isEditting = false;
          this.showToast("保存成功", "success", 5000);
          Bus.$emit("refreshTagDetail", true);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    customUpload(fileItem, insertFn) {
      //console.log(fileItem);
      File.getUploadSts()
        .then((res) => {
          this.status = "正在上传中";

          let info = res.data;
          let client = new OSS({
            region: "oss-cn-shanghai",
            accessKeyId: info.AccessKeyId,
            accessKeySecret: info.AccessKeySecret,
            stsToken: info.SecurityToken,
            bucket: "qinglitouyan",
          });

          let fileName = "fileStore/" + info.uuid + "/" + fileItem.name;

          client
            .multipartUpload(fileName, fileItem, {
              progress: (p) => {
                //获取进度条的值
                //this.progress = (p * 100).toFixed(2);
                this.editor.showProgressBar((p * 100).toFixed(2));
              },
            })
            .then((result) => {
              //下面是如果对返回结果再进行处理，根据项目需要
              let url = result.res.requestUrls[0];
              if (url.indexOf("uploadId") != -1) {
                url = /(.+(?=\?uploadId))/.exec(url)[0];
              }

              File.uploadFile(
                info.uuid,
                "/fileStore/" + info.uuid,
                fileItem.name,
                {}
              )
                .then((res) => {
                  //console.log(res);
                  let url = `${File.getApiUrl()}/api/file/${
                    info.uuid
                  }/downloadDirect`;
                  insertFn(url, "插图", url);
                })
                .catch((err) => {
                  console.log("err:", err);
                });
            })
            .catch((err) => {
              console.log("err:", err);
            });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
  },
  watch: {
    tagDetail(val, oldVal) {
      setTimeout(() => {
        this.editor.enable();
        this.editor.clear();

        if (this.tagDetail.snap) {
          this.editor.dangerouslyInsertHtml(this.tagDetail.snap);
        }

        this.editor.move(999999);
        this.editor.blur();
        this.editor.disable();

        let arr = this.editor.getElemsByType("image");
        arr.forEach((item) => {
          let el = document.getElementById(item.id);
          el.onclick = () => {
            Bus.$emit("previewFile", {
              url: item.src,
              name: item.alt + ".jpg",
            });
          };
        });
      }, 1000);
      //this.$forceUpdate();
    },
    isEditting(val) {
      if (val) {
        this.editor.enable();
      } else {
        this.editor.move(999999);
        this.editor.blur();
        this.editor.disable();
      }
    },
  },
};
</script>
<style scoped="scoped"></style>
